import iDoctorsLogo2 from "../../assets/iDoctors logo with text-01.png";
import buttonData from "./buttonsData";
import termsData from "./termsData";

import classes from './terms.module.css';
import { createRef, useRef } from "react";
const TermsConditions = () => {

  const scrollRefs = useRef([]);

  scrollRefs.current = [...Array(buttonData.length+1).keys()].map((_, i) => scrollRefs.current[i] ?? createRef())
  const scrollSmoothHandler = (index) => () => {
    scrollRefs.current[index].current.scrollIntoView({ block: 'end' });
  };
// console.log(scrollSmoothHandler)
  return (
    <>
      <div className={classes.privacyContainer}>
        <div className={classes.imgContainer}>
          <img src={iDoctorsLogo2} alt="" />
          <h1>Terms & Conditions </h1>
          <div className={classes.terms_button}>
            {buttonData.map((button) => (<button onClick={scrollSmoothHandler(button.id)} key={button.id}>{button.text}</button>
            ))}
          </div>
          {termsData.map((term) => (
            <div className={classes.terms_content} ref={scrollRefs.current[term.id]} key={term.id}>
              <h5>{term.title}</h5>
              <p>{term.text}</p>
            </div>
          ))}



        </div>
      </div>
    </>
  )
}
export default TermsConditions;