import React from "react";
import classes from "./sub.module.css";
import SubscribeNowIcon from "../../assets/icon/Subscribe now icon.png";

const Subscribe = () => {
  return (
    <div className={classes.subContainer}>
      <div className={classes.contactLOGO}>
        <h1>Subscribe Now</h1>
        <img src={SubscribeNowIcon} alt="" />
      </div>
      <form className={classes.footerForm}>
        <input type="text" placeholder="Store name" />
        <input type="email" placeholder="Full name" />
        <input type="email" placeholder="Email" />
        <input type="number" placeholder="Phone" />
        <input type="password" placeholder="Password" />
        <button className={classes.btn}>Try it now !</button>
      </form>
    </div>
  );
};

export default Subscribe;
