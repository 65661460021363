import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../home/plans.module.css";
import { BsCheck2Circle } from "react-icons/bs";

import { Element } from "react-scroll";
import { Link } from "react-router-dom";

const PlanMobile = () => {
  const [plans, setPlans] = useState([]);
  const [project, setProject] = useState(null);

  const [selected, setSelected] = useState(null);

  const getAllPlans = async () => {
    try {
      const res = await fetch(
        "https://py.e-marts.online/api/v1/plan?order_by=value_annual"
      );
      const data = await res.json();
      setPlans(data.results);

      if (res.status === 200) {
        const res = await fetch(
          `https://py.e-marts.online/api/v1/plan?planCode=${data.results[0].planCode}`
        );
        const dataRes = await res.json();
        setProject(dataRes?.results[0]?.id);
      }

      return data;
    } catch (error) {}
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const oenPlanHandler = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <Element name="Plans">
      <section className={classes.plans}>
        <Container fluid>
          <Row>
            {plans.length > 0
              ? plans.map((feature, index) => (
                  <Col key={feature.id} lg={3}>
                    <div
                      className={`${classes.plansContent} ${classes.plansContent2}`}
                    >
                      <div className={classes.namePlane}>
                        <h1>{feature.name}</h1>
                        <div className={classes.border}></div>
                        <div
                          className={classes.allDetails}
                          style={{ opacity: `${index === 3 && "0"}` }}
                        >
                          <div className={classes.month}>
                            <h6>{feature["value_monthly"]}</h6>
                            <span>EGP/MONTH</span>
                          </div>
                          <div className={classes.or}>
                            <span> ـــــــــــــOrـــــــــــــ</span>
                            <span>SAVE 20%</span>
                          </div>
                          <div className={classes.year}>
                            <h6>{feature["value_annual"]}</h6>
                            <span>EGP/YEAR</span>
                          </div>
                        </div>
                        <div className={classes.borderBottom}></div>
                      </div>
                      <div className={classes.seeFeaturesPlan}>
                        <button
                          className={classes.seeFeatures}
                          onClick={() => oenPlanHandler(index)}
                          disabled={index === 3}
                        >
                          See Features
                        </button>
                        {selected !== index && (
                          <button className={classes.subscribe}>
                            {index + 1 === plans[plans.length - 1].id ? (
                              <Link
                                to="/contactUs"
                                style={{
                                  color: "#fff",
                                  textDecoration: "none",
                                }}
                              >
                                Contact Us
                              </Link>
                            ) : (
                              "Subscribe Now"
                            )}
                          </button>
                        )}
                      </div>

                      <ul
                        className={`${classes.planUl} ${
                          selected === index && classes.planUlOpen
                        }`}
                      >
                        {feature.feature.map((f) => (
                          <li key={f.id}>{f.name}</li>
                        ))}
                        <li>Connect your own domain name</li>
                        <li>Up to {feature.Products} Products</li>
                        <li>{feature.Products} Categories</li>
                        <li>
                          {feature.Orders !== "*"
                            ? `Up to ${feature.Orders} Orders/month`
                            : "Unlimited orders"}
                        </li>
                        <li>{feature.Photos} UHD Photos</li>
                        <li>{feature.Videos} HD Videos</li>
                        {/* {feature.id === 4 && <li style={{ opacity: '0' }}><BsCheck2Circle className={classes.check} /></li>} */}
                      </ul>
                      {selected === index && (
                        <a
                          target={`_blank`}
                          href={`https://payment.teqneia.com/project/${project}?name=${feature.name}`}
                          className={classes.a}
                        >
                          Subscribe now
                        </a>
                      )}
                    </div>
                  </Col>
                ))
              : null}
          </Row>
        </Container>
      </section>
    </Element>
  );
};

export default PlanMobile;
