const termsData = [
    {
        'id': 100,
        "title": "Limitation of liabilities:",
        "text": <>{'“iDoctors” App provides a digital link for on-demand medical services, which functions as a platform for users who are seeking on-demand medical services. Users (“Patients”) search on the app can be matched with suitable service providers (“Doctors”).' }<br /> {'“iDoctors” app provides a platform with which patients can contact doctors. Patients can arrange and carry out an online consultation session with the doctors. The audio/video consultation session takes place directly between the users and the service providers via an encrypted connection. “iDoctors” only provides the communication session of the service. '}<br />{'“iDoctors” does not provide any medical advice and is not licensed as a medical expert or in any other medical profession. “iDoctors” doesn’t own any healthcare facilities. '}<br /> {'ARQAAM FZC exclusively operates the platform of iDoctors. Application owners do not offer any medical or therapeutic services. The consulting services are provided by independent third-party providers who are not employed by ARQAAM FZC or “iDoctors”.'}<br /> {'Each User shall create a User account that enables his access to the Application. The services provided by Doctors to Users are matched through the “iDoctors” App based on the user selection to his preferred service provider. '}<br /> {'“IDoctors” is not responsible for the content of communication between doctors and patients. However, iDoctors Customer care includes a section of integrated service satisfaction to get customers’ reviews after completing their services, in addition to handling their complaints & inquiries.'}</>,
    },
    {
        'id': 99,
        "title": "User Terms & Conditions",
        "text": <>{'The following terms and conditions are deemed the agreement form that ARQAAM FZC adopts to provide her/its services through ARQAAM FZC APP, by clicking “I accept these terms and conditions” user(s) undertakes legally to use the mentioned app and the provided service(s) in accordance with the applied laws and ministerial decrees in force in UAE.'} <br /> {'Also, by entering this agreement, user(s) is undertaking to authorize ARQAAM FZC APP to use/process his/her/its/their personal data including without limitation (Geolocation, Name, Detailed address, ID’s, personal photo, User biometric data, E-Mail, Phone or/and Mobile Number, ETC.)  As referred hereunder in the purpose of service providing or/and whenever needed. In return ARQAAM FZC undertakes to exert all due diligence in applying/following data security and protection standards in accordance with article 20 of Act No. 45 the year 2021.'}<br /> {'It is noteworthily that ARQAAM FZC upon its own discretion may vary or modify or change or update the above-mentioned terms and conditions. The user shall inspect these terms periodically. If the user continues using the App or the Services after any amendment, then it Shall be deemed as he expressly accepts these amendments.'}</>
    }, 
    {
        'id': 1,
        "title": <>{'1.'}&nbsp;&nbsp;{'Definitions'}</>,
        "text": <>{'- "user" or "You" or “Your" refers to you, as a user of the application and/or the Services. A user is someone who accesses or uses the Services for the purpose of sharing, displaying, hosting, publishing, transacting, or uploading information or views or pictures and includes other persons jointly participating in using the APP:   '}<br /> {'-	“ARQAAM FZC” is the application owner that is considered a mediator between the User(s) and service provider(s).'}<br /> {'-	”Service providers” are doctors or medical expert staff or medical entities such as hospitals and clinics who provide medical advice and consultancies.'}<br />{'-	“Company Content” means Content that ARQAAM FZC makes available through the Service or Application, including any Content licensed from a third party, but excluding User Content.'}<br />{'-	“User Content” means Content that a user posts, uploads, publishes, submits, or transmits to be made available through the Service or Application.'}<br /> {'-	“Collective Content” means, collectively, Company Content and User Content.'}</>,
    },
    {
        'id': 2,
        "title": <>{'2.'}&nbsp;&nbsp;{'Disclaimer of Warranties'}</>,
        "text": <>{'2.1.'}&nbsp;&nbsp;{'The user acknowledges and agrees that the services are provided "as is" and "as available" and that his use of the services shall be at his sole risk to the fullest extent permitted by applicable laws.'}<br /> {'2.2.'}&nbsp;&nbsp;{'ARQAAM FZC disclaims all warranties, explicit or implicit, in connection with the services including mobile apps and User’s account and/or usage of them to the fullest extent permitted by applicable laws, '}
                <br /> <br />{'2.3.'}&nbsp;&nbsp;{"To the fullest extent permitted by applicable laws, ARQAAM FZC makes no warranties or representations that the services have been and will be provided with due skill, care, and diligence or about the accuracy or completeness of the services' content and assumes no responsibility for any:"}<br />{'2.3.1.'}&nbsp;&nbsp;{'errors, mistakes, or inaccuracies of contents, '}<br />{'2.3.2.'}&nbsp;&nbsp;{'any unauthorized access to or use of our servers and/or all personal information stored therein, '}<br />
                {'2.3.3.'}&nbsp;&nbsp;{'any interruption or cessation of transmission to or from the services, '}<br />{'2.3.4.'}&nbsp;&nbsp;{'any bugs, viruses, trojan horses, or the like which may be transmitted to or through the services by the actions of any third party, '}<br />{'2.3.5.'}&nbsp;&nbsp;{'any loss of user’s data or contents from the services and/or '}<br />{'2.3.6.'}&nbsp;&nbsp;{'any errors or omissions in any contents or for any loss or damage of any kind incurred because of the use of any content posted, emailed, transmitted, or otherwise made available via the services. '}
               <br /> {'2.3.7.'}&nbsp;&nbsp;{"Any material downloaded or otherwise obtained using the services is done at user's own discretion and risk and User will be solely responsible for any damage to user’s computer system or other device or loss of data that results from the download of any such material."}<br /> {'2.3.8.'}&nbsp;&nbsp;{'ARQAAM FZC will not be a party to or in any way be responsible for monitoring any transaction between User and third-party providers of products or services. '} <br />{'2.3.9.'}&nbsp;&nbsp;{'No advice or information, whether oral or written, obtained by the User from ARQAAM FZC or through or from the services shall create any warranty not expressly stated in the terms.'}
               <br /> {'2.3.10.'}&nbsp;&nbsp;;{'Unless User has been expressly authorized to do so in writing by ARQAAM FZC, he/it agrees that in using the services, User will not use any trademark, service mark, trade name, or logo of any company or organization in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names, or logos.'}<br /> {'2.3.11.'}&nbsp;&nbsp;{'In no event, ARQAAM FZC shall not be liable for any losses, damages, or injury which may be incurred to the user or his licensors, including without limitation data loss or damage. By clicking “I accept these terms and conditions” you with your own free will expressly waive your right to rise/file a complaint(s) and/or lawsuit(s) or/and any other procedure(s) or/and any legal action(s) might be taken against ARQAAM FZC App.'}<br /> {'2.3.12.'}&nbsp;&nbsp;{'In no event, ARQAAM FZC does not guarantee the inability of not reaching service location, which may include without limitation individual movement to any locations not covered by data mobile services to provide the requested service or any delay that might occur by the lack of coverage of mobile operators or internet service providers.'}</>
    },
    {
        'id': 3,
        "title": <>{'3.'}&nbsp;&nbsp;{'Representations and Warranties'}</>,
        "text": <>{'3.1.'}&nbsp;&nbsp;{'By using the Application or Service, you expressly represent and warrant that the User is legally entitled to enter this Agreement. '}<br />{'3.2.'}&nbsp;&nbsp;{'If User resides in a jurisdiction that restricts the use of the Service because of age or restricts the ability to enter into agreements such as this one due to age, you must abide by such age limits and you must not use the Application or Service. '}<br />{'3.3.'}&nbsp;&nbsp;{'By using the Application or the Service, you represent and warrant that the User has the right, authority, and capacity to enter into this Agreement and to abide by the terms and conditions of this Agreement. Your participation in using the Service and/or Application is for your personal use and the use of others that have explicitly authorized you. '}<br />
                {'3.4.'}&nbsp;&nbsp;{'You may not authorize others to use your user status or credentials, and you may not assign or otherwise transfer your user account to any other person or entity. When using the Application or Service you agree to comply with all applicable laws from your home nation, the country, state, or Emirates, and the city in which you are present while using the Application or Service. '}<br /> {'3.5.'}&nbsp;&nbsp;{'Users may only access the Service using authorized means. ARQAAM FZC reserves the right to terminate this Agreement if you use the Service or Application with an incompatible or unauthorized device.'}<br />  {'3.6.'}&nbsp;&nbsp;{'By using the Application or the Service, User agree that:'}<br /> 
                <br /> {'3.6.1.'}&nbsp;&nbsp;{"User(s) will only use the Service or Application for lawful purposes; You will not use the Services for sending or storing any unlawful material or for fraudulent purposes."}<br />
                {'3.6.2.'}&nbsp;&nbsp;{'User(s) will not use the Service or Application to cause nuisance, annoyance, or inconvenience.'}<br />{'3.6.3.'}&nbsp;&nbsp;{'User(s) will not impair the proper operation of the network.'}<br />{'3.6.4.'}&nbsp;&nbsp;{'User(s) will not try to harm the Service or Application in any way whatsoever.'}<br />{'3.6.5.'}&nbsp;&nbsp;{'User(s) will not copy or distribute the Application or other content without written permission from ARQAAM FZC.'}
                <br />{'3.6.6.'}&nbsp;&nbsp;{'User(s) will only use the Application and Service for your own use and will not resell it to a third party.'}<br />{'3.6.7.'}&nbsp;&nbsp;{'User(s) will keep secure and confidential your account password or any identification we provide you which allows access to the Service.'}<br />{'3.6.8.'}&nbsp;&nbsp;{'User(s) will provide us with whatever proof of identity we may reasonably request.'}<br />{'3.6.9.'}&nbsp;&nbsp;{'User(s) will only use an access point, 3G, 4G or 5G data account (AP) which you are authorized to use.'}
                <br />{'3.6.10.'}&nbsp;&nbsp;{'License Grant, Restrictions, and Copyright Policy.'}<br />{'3.6.11.'}&nbsp;&nbsp;{'Licenses Granted by Company-to-Company Content and User Content.'}<br /><br />{'3.7.'}&nbsp;&nbsp;{'Subject to your compliance with the terms and conditions of this Agreement, ARQAAM FZC grants you a limited, non-exclusive, non-transferable license:'}<br />
                <br />{'3.7.1.'}&nbsp;&nbsp;{'to view, download and print any doctor prescriptions via ARQAAM FZC’s application for User’s personal and non-commercial purposes; and'} <br />{'3.7.2.'}&nbsp;&nbsp;{'You have no right to sublicense the license rights granted in this Agreement.'}<br />{'3.7.3.'}&nbsp;&nbsp;{'You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, or otherwise exploit the Service, Application, or Collective Content, except as expressly permitted in this Agreement. '}<br />{'3.7.4.'}&nbsp;&nbsp;{'No licenses or rights of any kind are granted to you by implication or otherwise by ARQAAM FZC or its licensors, except for the licenses and rights expressly granted in this section.'}</>,
    },
    {
        'id': 4,
        "title":  <>{'4.'}&nbsp;&nbsp;{'Advertising (third party interaction)'}</>,
        "text": <>{'   Advertisements may be targeted to the contents of information stored on the Services, queries made through the Services, or other information. '}
                <br />{'4.1.'}&nbsp;&nbsp;{'The manner, mode, and extent of advertising by ARQAAM FZC on the Services are subject to change without specific notice to both users and/or service providers. '}<br />{'4.2.'}&nbsp;&nbsp;{'In consideration for ARQAAM FZC granting user access to and use of the Services, the user agrees that ARQAAM FZC may place such advertising on the Services.'}<br />{'4.3.'}&nbsp;&nbsp;{'Part of the mobile application and/or the website may contain advertising information or promotional materials, or other materials submitted to ARQAAM FZC mobile apps by third parties or Customers. Responsibility for ensuring that material submitted for inclusion on the ARQAAM FZC mobile apps complies with applicable international and national laws is exclusively with the party providing the information/material. '}
                <br />{'4.4.'}&nbsp;&nbsp;{'User’s correspondence or business dealings with, or participation in promotions of, advertisers other than ARQAAM FZC found on or through the ARQAAM FZC mobile apps, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, shall be solely between user and such advertiser. ARQAAM FZC will not be responsible or liable for any error or omission, inaccuracy in advertising material, or any loss or damage of any sort incurred as a result of any such dealings or as a result of the presence of such other advertiser(s) on the ARQAAM FZC mobile application. '}<br />{'4.5.'}&nbsp;&nbsp;{'For any information related to a charitable campaign ("Charitable Campaign") sent to Customers and/or displayed on the ARQAAM FZC app. where Customers have an option to donate money by way of:'}
                <br />{'4.5.1.'}&nbsp;&nbsp;;{'payment on a third-party website; or'} <br />{'4.5.2.'}&nbsp;&nbsp;{'depositing funds to a third-party bank account, ARQAAM FZC is not involved in any manner in the collection or utilization of funds collected pursuant to the Charitable Campaign. ARQAAM FZC does not accept any responsibility or liability for the accuracy, completeness, legality, or reliability of any information related to the Charitable Campaign. Information related to the Charitable Campaign is displayed for informational purposes only and Customers are advised to do an independent verification before taking any action in this regard.'}</>,
    },
    {
        'id': 5,
        "title": <>{'5.'}&nbsp;&nbsp;{'Payment, cancellation & Refund policy'}</>,
        "text": <> {'5.1.'}&nbsp;&nbsp;{'Payment: '}<br /><br/> {'5.1.1.'}&nbsp;&nbsp;{'Any fees that ARQAAM FZC may charge you for the use of its application or Service, are due Immediately through ARQAAM FZC’s App.'}<br/> {'5.1.2.'}&nbsp;&nbsp;{'ARQAAM FZC reserves the right to determine the final prevailing pricing'}
        <br/> {'5.1.3.'}&nbsp;&nbsp;{'Pricing information published on the website, or the application may slightly vary from the final prevailing price.'}<br/> {'5.1.4.'}&nbsp;&nbsp;{'ARQAAM FZC, at its sole discretion, makes promotional offers with different features and different rates to its customers. These promotional offers, unless made to you, shall have no bearing whatsoever on your offer or any contract with ARQAAM FZC.'}<br/> {'5.1.5.'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'ARQAAM FZC may change the fees for the Service or Application, as we deem necessary for our business. ARQAAM FZC encourages you to check back at our website or / and the application periodically if you are interested in ARQAAM FZC charges for the Service or Application.'}<br/> {'5.1.6.'}&nbsp;&nbsp;{'ARQAAM FZC accepts payments online using Visa and MasterCard credit/debit cards in AED (or any other user local currency) '}
        <br /> <br /> {'5.2.'}&nbsp;&nbsp;{'Service request Cancellation & Refund Policy'} <br /> <br />
        {'5.2.1.'}&nbsp;&nbsp;{'ARQAAM FZC will only consider the cancellation requests that have been submitted within 24 hours, any requests that exceed this limit will be declined. '} <br /> 
        {'5.2.2.'}&nbsp;&nbsp;{'In no event, ARQAAM FZC will not afford any banking or/and administrative fees arising because of the submitted cancellation request.  '} <br /> 
        {'5.2.3.'}&nbsp;&nbsp;{'Any cancellation request posts a session made by ARQAAM FZC application will be reviewed by ARQAAM FZC customer service team to assess the cancelation request and take the proper action whether decline the request in case of service provided successfully or redeem the user with his paid amount in case of the service was not provided successfully.'} <br /> 
        {'5.2.4.'}&nbsp;&nbsp;{'Refunds will be done only through the Original Mode of Payment.'} <br /> </>,
        
    },
    {
        'id': 6,
        "title": <>{'6.'}&nbsp;&nbsp;{'Indemnification'}</>,
        "text": <>{'By entering into this Agreement and/or using the Application, User agrees that he/she shall, indemnify and hold the ARQAAM FZC, harmless from and against all claims, costs, damages, losses, liabilities, and expenses (including attorneys’ fees and costs) arising out of or in connection with:'}
        <br />{"a)"}&nbsp;&nbsp;{"User’s violation or breach of any term of this Agreement or any applicable law or regulation, whether referred to in this Agreement. "}<br />{"b)"}&nbsp;&nbsp;{"User’s violation of any rights of any third party."}<br />{"c)"}&nbsp;&nbsp;&nbsp;{"User’s usage or misuse of the Application or Service. "}</>,
    },
    {
        'id': 7,
        "title": <>{'7.'}&nbsp;&nbsp;{'Notice'}</>,
        "text": <>{'ARQAAM FZC and users’ usual correspondence is carried out through the customer service app communication tool. In case of legal dispute only, both parties have agreed that mutual correspondence, including but not limited to, Notices, Judicial notices, warning letters, and similar correspondences shall be valid, and its legal effect will be enforced on both parties whether it has been delivered by hand, Email, or Emirate’s post, through the addresses registered. User address should be registered in the app. ARQAAM FZC address will be registered on the website. '}<br />{'In case of legal dispute and in the event of changing the indicated addresses above both parties undertake to notify the other party in writing of the new address(es), otherwise, all correspondences sent to the indicated address(es) shall be deemed legally valid and enforced.'}</>,
    },
    {
        'id': 8,
        "title": <>{'8.'}&nbsp;&nbsp;{'Assignment'}</>,
        "text": <>{'This Agreement may not be assigned by the user without the prior written approval of ARQAAM FZC, however, ARQAAM FZC may be assigned without the consent of the user in several cases including but not limited to:'}
        <br/> {'a.'}&nbsp;&nbsp;{'a parent or subsidiary, '} <br/> {'b.'}&nbsp;&nbsp;{'an acquirer of ARQAAM FZC’s application or/and website. '} <br/> {'c.'}&nbsp;&nbsp;{'a successor by merger. Any purported assignment in violation of this section shall be void. '}</>,
    },
        {
        'id': 9,
        "title": <>{'9.'}&nbsp;&nbsp;{'Force Majeure'}</>,
        "text": <>{'ARQAAM FZC will not be liable in damages or otherwise for any failure or delay in performance of any obligations hereunder other than an obligation to make payment, where such failure or delay is caused by force majeure, being any event, occurrence, or circumstance reasonably beyond the control of that party, including without prejudice to the generality of the foregoing failure or delay caused by or resulting from acts of God, pandemics, epidemics, strikes, fires, floods, wars (whether declared or undeclared), riots, embargoes, accidents, restrictions imposed by any governmental authority or any other man-made/natural disasters.'}</>,
    },
    {
        'id': 10,
        "title": <>{'10.'}&nbsp;&nbsp;{'Termination '}</>,
        "text": <>{'ARQAAM FZC - on its sole discretion - entitled to terminate, close, temporary or/and suspend the user account at any time during its process/validity and the user., By clicking on accept terms and conditions, is waiving the right to protest or claim for any indemnity or/and compensation or any other claims ever.'}</>,
    },
    {
        'id': 11,
        "title": <>{'11.'}&nbsp;&nbsp;{'Governing Law, litigation jurisdiction, and Dispute Resolution'}</>,
        "text": <>{'Any dispute, claim, or controversy arising out of or in connection with this Agreement, including a dispute, claim or controversy arising in relation to its interpretation or relating to any non-contractual obligations arising out of, or in connection with this agreement (a “Dispute”) shall be settled amicably between the parties following the receipt by either party of written notice of the dispute from the other party. '}
                <br />{'In the event that a Dispute cannot be settled amicably within a period of 60 days from the date on which the relevant party notifies the other in writing that a Dispute has arisen, the parties agree that such Dispute shall be referred to and finally settled by Dubai Courts.'}
                <br/>{'This Agreement is governed by the concerned laws and/or ministerial decrees that are applied in the United Arab Emirates.'} 
                <br /> {'Any dispute or claim arising out of or in connection with this mobile app. "ARQAAM FZC" shall be governed and construed in accordance with the laws of the UAE.'} 
                <br /> {'Dubai Courts have jurisdiction over disputes related to the implementation of the provisions of this contract and the interpretation of its clause.'} 
                </>,
    },
   
]
export default termsData;