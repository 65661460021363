import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./help.module.css";
import HelpCenterIcon from "../../assets/icon/Help Center icon.png";
import { Collapse } from "antd";
const { Panel } = Collapse;

const text1 = `You can sign-up for a monthly or annual subscriptions, whatever best suits your business needs.`;
const text2 = `For the ultimate maintenance of your data, you could only upgrade. `;
const text3 = `Yes, you can connect your domain name`;
const text4 = `No, you can sign-up first to our free trial without any payment details.`;
const text5 = `Yes of course, you can contact us now, and let’s get started.`;
const text6 = `In E-marts, you have access to 20+ design templates, whereas you can change it however you like. `;

const HelpCenter = () => {
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className={classes.helpContainer}>
      <div className={classes.helpContent}>
        <Container fluid>
          <Row>
            <Col lg={2}>
              <div className={classes.imgContainer}>
                <img src={HelpCenterIcon} alt="" />
              </div>
            </Col>
            <Col lg={9}>
              <div className={classes.expandContainer}>
                <Collapse defaultActiveKey={["1"]} onChange={onChange}>
                  <Panel
                    header="What is the minimum period of the contract?"
                    key="1"
                  >
                    <p>{text1}</p>
                  </Panel>
                  <Panel header="Can I upgrade or downgrade anytime?" key="2">
                    <p>{text2}</p>
                  </Panel>
                  <Panel
                    header="I already have a domain name, can I use it with my e-marts store?"
                    key="3"
                  >
                    <p>{text3}</p>
                  </Panel>
                  <Panel
                    header="Do I need to enter my payment details to sign up?"
                    key="4"
                  >
                    <p>{text4}</p>
                  </Panel>
                  <Panel
                    header="Can I request specific customization?"
                    key="5"
                  >
                    <p>{text5}</p>
                  </Panel>
                  <Panel
                    header="Can I request a custom unique design for my store?"
                    key="6"
                  >
                    <p>{text6}</p>
                  </Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HelpCenter;
