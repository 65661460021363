import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./howItWorks.module.css";
import signUpNow from "../../assets/imgsDoctors/sign up now.png";
import chooseYourService from "../../assets/imgsDoctors/choose your service.png";
import chooseSpecialty from "../../assets/imgsDoctors/choose specialty.png";
import done from "../../assets/imgsDoctors/done.png";
import doneCheck from "../../assets/imgsDoctors/done check.png";

import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const [t, i18n] = useTranslation();
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);

  return (
    <Element name="HowItWorks">
      <div className={`${classes.features}`}>
        <Container fluid>
          <h3>{t("How iDoctors works?")}</h3>
          <Row>
            <Col lg={3} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <h3 >{t("Sign-up now")}</h3>
                <img src={signUpNow} alt="" />
              </div>
            </Col>
            <Col lg={3} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <h3>{t("Choose your service")}</h3>
                <img src={chooseYourService} alt="" />
              </div>
            </Col>
            <Col lg={3} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <h3 >{t("Choose Specialty")}</h3>
                <img src={chooseSpecialty} alt="" />
              </div>
            </Col>
            <Col lg={3} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3 >{t("Get in touch with your doctor")}</h3>
                  {/* <img src={doneCheck} alt="" /> */}
                </div>
                <img src={done} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Element>
  );
};

export default HowItWorks;
