import React from "react";
import { Toaster } from "react-hot-toast";
import Footer from "../components/footer/Footer";
import Features from "../components/home/Features";
import Header from "../components/home/Header";
import HowItWorks from "../components/home/HowItWorks";
import JoinDoctor from "../components/home/JoinDoctor";
import ReadyToUse from "../components/home/ReadyToUse";

const HomePage = () => {
  return (
    <div className="backgroundIMG">
      {/* <NavbarComponent /> */}
      <Toaster />
      <Header />
      <Features />
      <HowItWorks />
      <ReadyToUse />
      <JoinDoctor />
      <Footer />
      {/* <Business /> */}
    </div>
  );
};

export default HomePage;
