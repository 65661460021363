import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContactUs from "./components/contactUs/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import Pricing from "./components/pricing/Pricing";
import NavbarComponent from "./components/home/NavbarComponent";
import Plans from "./components/plans/Plans";
import Subscribe from "./components/plans/Subscribe";
import HelpCenterPage from "./pages/HelpCenterPage";
import HomePage from "./pages/HomePage";
import PlanMobile from "./components/planMobile/PlanMobile";
import Privacy from "./components/privacy/Privacy";
import TermsConditions from "./components/termsConditions/TermsConfitions";
import ScrollTop from "./ScrollTop";

const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1000;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [width]);

  return (
    <div className="App">
      <Router>
      <ScrollTop >
        <NavbarComponent />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/helpcenter">
            <HelpCenterPage />
          </Route>
          <Route exact path="/subscribe">
            <Subscribe />
          </Route>
          <Route exact path="/plans">
            {width < breakpoint ? <PlanMobile /> : <Plans />}
          </Route>
          <Route exact path="/pricing">
          <Pricing />
          </Route>
          <Route exact path="/aboutus">
            <AboutUs />
          </Route>
          <Route exact path="/contactUS">
            <ContactUs />
          </Route>
          <Route exact path="/privacyPolicy">
            <Privacy />
          </Route>
          <Route exact path="/termsConditions">
            <TermsConditions />
          </Route>
        </Switch>
        </ScrollTop>
      </Router>
    </div>
  );
};

export default App;
