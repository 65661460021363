import React, { useState } from "react";
import classes from "./contact.module.css";
import { Col, Container, Row } from "react-bootstrap";
import MessageUsIcon from "../../assets/imgsDoctors/Message us icon.png";
import contactUsIcon from "../../assets/imgsDoctors/contact us icon.png";
import Footer from "../footer/Footer";
import { useTranslation } from "react-i18next";
import { toast, Toaster } from "react-hot-toast";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const submitForm = async (ev) => {
    ev.preventDefault();

    const formData = new FormData();
    const ipResp = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResp.json();
    const ip = ipData.ip;

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("company_name", "IDoctor");
    formData.append("message_body", message);
    formData.append("ip", ip);

    try {
      const response = await fetch(
        "https://py.tws.teqneia.com/contacts/?tenant_id=16",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Message successfully sent.");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      toast.error("Unable to send. Please try again.");
      console.error("Error submitting form:", error);
    }
  };

  const [t] = useTranslation();
  return (
    <>
      <Toaster />
      <div className={classes.subContainer}>
        <Container>
          <Row>
            <Col lg={6}>
              <div className={classes.contactLOGO}>
                <h1>{t("contactUs")}</h1>
                <img src={MessageUsIcon} alt="" />
              </div>
              <form className={classes.footerForm} onSubmit={submitForm}>
                <h3>{t("Send us an email")}</h3>
                <input
                  type="text"
                  placeholder={t("name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder={t("email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="number"
                  placeholder={t("number")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id=""
                  cols="30"
                  rows="10"
                  placeholder={t("message")}
                />
                <button className={classes.btn}>{t("send")}</button>
              </form>
            </Col>
            <Col lg={6} className="d-none d-lg-block">
              <div className={classes.contactUsIcon}>
                <img src={contactUsIcon} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
