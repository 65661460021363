import React, { useEffect, useState } from "react";
import { Col, Container, Modal, ProgressBar, Row } from "react-bootstrap";
import classes from "./readyToUse.module.css";
import Patient1 from "../../assets/imgsDoctors/Patient1.png";
import Patients from "../../assets/imgsDoctors/Patients.png";
import appStore from "../../assets/imgsDoctors/app store.png";
import googlePlay from "../../assets/imgsDoctors/google play.png";

import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Button } from "bootstrap";

const ReadyToUse = () => {
  const [t, i18n] = useTranslation();
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);
  
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    // <Element name="ReadyToUse">
    //   <div className={`${classes.features}`} style={{ direction: "ltr" }}>
    //     <Container fluid>
    //       <h3>{t("Ready to use ?")}</h3>
    //       <Row
    //         style={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "flex-end",
    //         }}
    //       >
    //         <Col lg={3} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations1
    //             }`}
    //           >
    //             <img src={Patient1} alt="" className={classes.Patient1} />
    //           </div>
    //         </Col>
    //         <Col lg={5} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations2
    //             }`}
    //           >
    //             <div className={classes.useContainer}>
    //               <p>{t("Ready to use paragraph")}</p>
    //               <div className={classes.socialMedia}>
    //                 <img src={appStore} alt="" />
    //                 <img src={googlePlay} alt="" />
    //               </div>
    //             </div>
    //           </div>
    //         </Col>
    //         <Col lg={3} className="col-md-12">
    //           <div
    //             className={`${classes.featuresContent} ${
    //               animation > breakpoint && classes.animations3
    //             }`}
    //           >
    //             <img src={Patients} alt="" className={classes.Patients} />
    //           </div>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </Element>
    <div className={`${classes.features}`} style={{ direction: "ltr" }}>
      <Container fluid>
        <h3>{t("Ready to use ?")}</h3>
        <div>
          <div
            className={`${classes.featuresContent} ${
              animation > breakpoint && classes.animations2
            }`}
          >
            <img src={Patient1} alt="" className={classes.Patient1} />
            <div className={classes.useContainer}>
              <p>{t("Ready to use paragraph")}</p>
              <div className={classes.socialMedia}>
                <img onClick={handleShow} src={appStore} className={classes.appGooglePlay}  alt="" />
                <Modal  onHide={handleClose} show={show} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className={classes.modalComing}>
                <h1>Coming soon..</h1>
                <ProgressBar now={50} />
                </div>
                </Modal>
                <a href="https://play.google.com/store/apps/details?id=com.arqaam.i_doctors_app" className={classes.appGooglePlay}>
                
                <img  src={googlePlay} alt="" />
                </a>
            
              </div>
            </div>
            <img src={Patients} alt="" className={classes.Patients} />
          </div>
        </div>
      </Container>
    
    </div>
    
  );
};

export default ReadyToUse;
