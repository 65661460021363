const buttonData = [
    {
        id: 1,
        text: "Difinitions"
    },
    {
        id: 2,
        text: "Disclaimer Of Warranties"
    },
    {
        id: 3,
        text: "Representations And Warranties"
    },
    {
        id: 4,
        text: "Advertising"
    },
    {
        id: 5,
        text: "Payment"
    },
    {
        id: 6,
        text: "Indemnigication"
    }, 
    {
        id: 7,
        text: "Notice"
    }, 
    {
        id: 8,
        text: "Assignment"
    }, 
    {
        id: 9,
        text: "Force Majeure"
    },
    {
        id: 10,
        text: "Termination"
    },
    {
        id: 11,
        text: "Governing Law, Litigation Jurisdiction, And Dispute Resolution"
    },
]

export default buttonData;
