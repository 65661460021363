import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import classes from "./plans.module.css";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

import planIMG from "../../assets/icon/plan full icon with word.png";
import StarterIMG from "../../assets/icon/Starter full icon with word.png";
import PremiumIMG from "../../assets/icon/Premium full icon with word.png";
import ProfessionalIMG from "../../assets/icon/Professional full icon with word.png";
import EnterpriseIMG from "../../assets/icon/Enterprise Full icon with word.png";
import NoIcon from "../../assets/icon/No icon.png";
import yesIcon from "../../assets/icon/yes icon.png";
import starIcon from "../../assets/icon/star icon.png";
import { Link } from "react-router-dom";
import paypalLogo from "../../assets/icon/paypal logo.png";
import GeideaLogo from "../../assets/icon/Geidea.png";
import paymobLogo from "../../assets/icon/paymob logo.png";
import Asset1 from "../../assets/icon/Asset 1.png";

const Plans = () => {
  const [features, setFeatures] = useState([]);
  const [plans, setPlans] = useState([]);
  const [project, setProject] = useState(null);
  const [detailsPlans, setDetailsPlans] = useState([
    "Storage space",
    "Number of products",
    "Accessible design",
    "Integration with payment gateways",
    "staff_account",
  ]);

  const getAllFeatures = async () => {
    try {
      const res = await fetch(
        "https://py.e-marts.online/api/v1/plan/feature?order_by=-id"
      );
      const data = await res.json();
      setFeatures(data.results);

      return data;
    } catch (error) {}
  };

  useEffect(() => {
    getAllFeatures();
  }, []);

  const getAllPlans = async () => {
    try {
      const res = await fetch(
        "https://py.e-marts.online/api/v1/plan?order_by=value_annual"
      );
      const data = await res.json();
      setPlans(data.results);

      if (res.status === 200) {
        const res = await fetch(
          `https://py.e-marts.online/api/v1/plan?planCode=${data.results[0].planCode}`
        );
        const dataRes = await res.json();
        setProject(dataRes?.results[0]?.id);
      }

      return data;
    } catch (error) {}
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const tooltip = (
    <Tooltip id="tooltip" style={{ background: "#ddd !important" }}>
      <strong
        style={{
          fontSize: "10px",
          background: "none !important",
          width: "10px !important",
        }}
      >
        This amount of compensation paid to a person based on the amount of
        sales generated.
      </strong>
    </Tooltip>
  );


  return (
    <section className={classes.plans}>
      <Container fluid>
        <h3>Plans</h3>
        <p>Join, Sell & Earn.</p>
        <Row>
          <Col lg={3}>
            <div className={classes.allFeature}>
              <img src={planIMG} alt="" />
              <h2>All Features</h2>
              <div className={classes.border}></div>
              <ul>
                {features.slice(16).map((feature) => (
                  <li key={feature.id}>
                    {feature.name}
                    <OverlayTrigger
                      className={classes.toltip}
                      placement="right"
                      overlay={tooltip}
                    >
                      <Button className={classes.toltipBtn}>
                        <HiOutlineQuestionMarkCircle className={classes.mark} />
                      </Button>
                    </OverlayTrigger>
                  </li>
                ))}
                {features.slice(0, 16).map((feature) => (
                  <li key={feature.id}>
                    {feature.name}
                    <OverlayTrigger
                      className={classes.toltip}
                      placement="right"
                      overlay={tooltip}
                    >
                      <Button className={classes.toltipBtn}>
                        <HiOutlineQuestionMarkCircle className={classes.mark} />
                      </Button>
                    </OverlayTrigger>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={9}>
            <Row style={{ display: "flex", alignItems: "flex-end" }}>
              <Col lg={3}>
                <div className={classes.imgContainer}>
                  <img src={StarterIMG} alt="" />
                </div>
              </Col>
              <Col lg={3}>
                <div className={classes.imgContainer}>
                  <img src={PremiumIMG} alt="" />
                </div>
              </Col>
              <Col lg={3}>
                <div className={classes.imgContainer}>
                  <img src={ProfessionalIMG} alt="" />
                </div>
              </Col>
              <Col lg={3}>
                <div className={classes.imgContainer}>
                  <img src={EnterpriseIMG} alt="" />
                </div>
              </Col>
            </Row>
            {/* **********setup fees*********** */}
            {/* <div className={classes.setupFees}>
              <Row style={{ textAlign: "center" }}>
                <Col lg={3}>
                  <span>SETUP FEES</span>
                </Col>
                <Col lg={3}>
                  <span style={{ textDecoration: "line-through" }}>
                    {plans[0]?.value_setup}EGP
                  </span>
                </Col>
                <Col lg={3}>
                  <span>0EGP</span>
                </Col>
                <Col lg={3}>
                  <span style={{ color: "#f17e83" }}>
                    <span style={{ color: "#d85f65" }}>LIMITED</span> TIME OFFER
                    !!
                  </span>
                </Col>
              </Row>
            </div> */}
            <div className={classes.setupFeesEdit}>
              <div className={classes.feesContainer}>
                <span>SETUP FEES</span>
                <span style={{ textDecoration: "line-through" }}>
                  {plans[0]?.value_setup}EGP
                </span>
                <span>0EGP</span>
              </div>
              <img src={Asset1} alt="" />
            </div>
            {/* **********all plans*********** */}
            <Row>
              {plans.slice(0, 3)?.map((plan) => (
                <Col lg={3}>
                  <div
                    className={`${classes.plan} ${
                      plan.id === 2 && classes.plan2
                    } ${plan.id === 3 && classes.plan3}`}
                  >
                    <h6>{plan["value_monthly"]}</h6>
                    <span>EGP / MONTH</span>
                    <div className={classes.or}>
                      __________ <span>OR</span> __________
                    </div>
                    <h4>SAVE 20%</h4>
                    <h5>{plan["value_annual"]}</h5>
                    <span>EGP / YEAR</span>
                  </div>
                </Col>
              ))}
              {/* <Col lg={3}>
                <div className={classes.plan}>
                  <h6>299</h6>
                  <span>EGP / MONTH</span>
                  <div className={classes.or}>
                    __________ <span>OR</span> __________
                  </div>
                  <h4>SAVE 20%</h4>
                  <h5>2870</h5>
                  <span>EGP / YEAR</span>
                </div>
              </Col>
              <Col lg={3}>
                <div className={`${classes.plan} ${classes.plan2}`}>
                  <h6>299</h6>
                  <span>EGP / MONTH</span>
                  <div className={classes.or}>
                    __________ <span>OR</span> __________
                  </div>
                  <h4>SAVE 20%</h4>
                  <h5>2870</h5>
                  <span>EGP / YEAR</span>
                </div>
              </Col>
              <Col lg={3}>
                <div className={`${classes.plan} ${classes.plan3}`}>
                  <h6>299</h6>
                  <span>EGP / MONTH</span>
                  <div className={classes.or}>
                    __________ <span>OR</span> __________
                  </div>
                  <h4>SAVE 20%</h4>
                  <h5>2870</h5>
                  <span>EGP / YEAR</span>
                </div>
              </Col> */}
              <Col lg={3}>
                <div className={classes.planCustomized}>CUSTOMIZED</div>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <div className={classes.noCommission}>
                  NO COMMMISSON ON SALES
                </div>
                <div className={classes.security}>FREE SSL SECURITY</div>
                <div className={classes.yourDomain}>
                  CONNECT YOUR OWN DOMAIN
                </div>
              </Col>
              <Col lg={3}>
                <div className={classes.allFeatures}>ALL FEATURES</div>
              </Col>
            </Row>
            {/* Get all features */}
            <Row>
              {plans.slice(0, 3).map((plan) => (
                <Col lg={3} key={plan.id}>
                  <div className={classes.borderPlans}>
                    <>
                      {features.map((feature) => (
                        <div
                          className={`${plan.id === 1 && classes.planMap4} ${
                            plan.id === 2 && classes.planMap5
                          } ${plan.id === 3 && classes.planMap6}`}
                        >
                          {detailsPlans.map(
                            (e) =>
                              feature.name === e && (
                                <div className={classes.detailsPlans}>
                                  {/* account */}
                                  {plan[e] === 1 && e === "staff_account" && (
                                    <span>{plan[e]} Account</span>
                                  )}{" "}
                                  {plan[e] > 1 &&
                                    plan[e] < 1000000 &&
                                    e === "staff_account" && (
                                      <span>{plan[e]} Accounts</span>
                                    )}{" "}
                                  {plan[e] >= 1000000 &&
                                    e === "staff_account" && (
                                      <span>Unlimited</span>
                                    )}{" "}
                                  {/* templates */}
                                  {plan["templates"] === 1 &&
                                    e === "Accessible design" && (
                                      <span>{plan["templates"]} templates</span>
                                    )}{" "}
                                  {plan["templates"] > 1 &&
                                    plan["templates"] < 1000000 &&
                                    e === "Accessible design" && (
                                      <span>{plan["templates"]} templates</span>
                                    )}{" "}
                                  {plan["templates"] >= 1000000 &&
                                    e === "Accessible design" && (
                                      <span>All Templates 100+</span>
                                    )}{" "}
                                  {/* *******************Products******************** */}
                                  {plan["Products"] === 1 &&
                                    e === "Number of products" && (
                                      <span>{plan["Products"]} Products</span>
                                    )}{" "}
                                  {plan["Products"] > 1 &&
                                    plan["Products"] < 1000000 &&
                                    e === "Number of products" && (
                                      <span>{plan["Products"]} Products</span>
                                    )}{" "}
                                  {plan["Products"] >= 1000000 &&
                                    e === "Number of products" && (
                                      <span>Unlimited</span>
                                    )}{" "}
                                  {/* ********************gateways********************** */}
                                  {plan["gateways"] === 1 &&
                                    e ===
                                      "Integration with payment gateways" && (
                                      <span>
                                        <img
                                          className={classes.paypalLogo}
                                          src={paypalLogo}
                                          alt=""
                                        />
                                      </span>
                                    )}{" "}
                                  {plan["gateways"] === 2 &&
                                    e ===
                                      "Integration with payment gateways" && (
                                      <>
                                        <img
                                          className={classes.paypalLogo}
                                          src={paypalLogo}
                                          alt=""
                                        />
                                        +{" "}
                                        <img
                                          className={classes.GeideaLogo}
                                          src={GeideaLogo}
                                          alt=""
                                        />
                                      </>
                                    )}{" "}
                                  {plan["gateways"] === 3 &&
                                    e ===
                                      "Integration with payment gateways" && (
                                      <>
                                        <img
                                          className={classes.paypalLogo}
                                          src={paypalLogo}
                                          alt=""
                                        />
                                        +{" "}
                                        <img
                                          className={classes.GeideaLogo}
                                          src={GeideaLogo}
                                          alt=""
                                        />
                                        +{" "}
                                        <img
                                          className={classes.paymobLogo}
                                          src={paymobLogo}
                                          alt=""
                                        />
                                      </>
                                    )}{" "}
                                  {/* Storage */}
                                  {plan["storage"] >= 1024 &&
                                    plan["storage"] < 1000000 &&
                                    e === "Storage space" && (
                                      <span>{plan["storage"] / 1024} GB</span>
                                    )}{" "}
                                  {plan["storage"] < 1024 &&
                                    e === "Storage space" && (
                                      <span>{plan["storage"]} MB</span>
                                    )}
                                  {plan["storage"] >= 1000000 &&
                                    e === "Storage space" && (
                                      <span>Unlimited</span>
                                    )}
                                  {/* {feature.name === "storage" && (
                                    <span>GB</span>
                                  )} */}
                                  {/* <span>{plan[e]}</span> */}
                                </div>
                              )
                          )}
                          {/* {plan.feature.find((e) => e.id === feature.id) ? (
                            // <p>{feature.name}</p>
                            <div className={classes.imgContainerIconRight}>
                              <img src={yesIcon} alt="" />
                            </div>
                          ) : (
                            <div className={classes.imgContainerIcon}>
                              <img src={NoIcon} alt="" />
                            </div>
                          )} */}
                        </div>
                      ))}
                    </>
                    <Col lg={12}>
                      {/* <div className={classes.imgContainerIconRight}>done</div> */}
                      {features.slice(5, 16).map((feature) => (
                        <div
                          className={`${plan.id === 1 && classes.planMap} ${
                            plan.id === 2 && classes.planMap2
                          } ${plan.id === 3 && classes.planMap3}`}
                        >
                          {plan.feature.find((e) => e.id === feature.id) ? (
                            // <p>{feature.name}</p>
                            <div className={classes.imgContainerIconRight}>
                              <img src={yesIcon} alt="" />
                            </div>
                          ) : (
                            <div className={classes.imgContainerIcon}>
                              <img src={NoIcon} alt="" />
                            </div>
                          )}
                        </div>
                      ))}
                    </Col>
                    <button className={classes.SubscribeBtn}>
                      <a
                        target={`_blank`}
                        href={`https://payment.teqneia.com/project/${project}?name=${plan.name}`}
                      >
                        Subscribe now
                      </a>
                    </button>
                  </div>
                </Col>
              ))}
              <Col lg={3}>
                <div className={classes.showIMGSContainer}>
                  <div className={classes.showIMGS}>
                    <img src={yesIcon} alt="" />
                    <img src={starIcon} alt="" />
                  </div>
                  <button
                    className={classes.SubscribeBtn}
                    style={{ marginBottom: "10px" }}
                  >
                    <Link to="/contactUS">Contact Us</Link>
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Plans;
