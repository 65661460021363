import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./features.module.css";
import icon1 from "../../assets/imgsDoctors/tap.png";
import icon2 from "../../assets/imgsDoctors/protected.png";
import icon3 from "../../assets/imgsDoctors/family.png";
import icon4 from "../../assets/imgsDoctors/all.png";
import icon5 from "../../assets/imgsDoctors/world.png";

import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

const Features = () => {
  const [t, i18n] = useTranslation();
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 66;

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);

  return (
    <Element name="Features">
      <div className={`${classes.features}`}>
        <Container fluid>
          <h3>{t("featuresH3")}</h3>
          <Row>
            <Col lg={4} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations1
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3>{t("featuresH3one")}</h3>
                  <img src={icon1} alt="" />
                </div>
                <p>{t("featurespOne")}</p>
              </div>
            </Col>
            <Col lg={4} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations2
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3>{t("featuresH3Two")}</h3>
                  <img src={icon2} alt="" />
                </div>
                <p>{t("featurespTwo")}</p>
              </div>
            </Col>
            <Col lg={4} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations3
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3>{t("featuresH3Three")}</h3>
                  <img src={icon3} alt="" />
                </div>
                <p>{t("featurespThree")}</p>
              </div>
            </Col>
            <Col lg={4} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations4
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3>{t("featuresH3Four")}</h3>
                  <img
                    src={icon4}
                    alt=""
                    style={{ marginLeft: "20px", marginBottom: "8px" }}
                  />
                </div>
                <p>{t("featurespFour")}</p>
              </div>
            </Col>
            <Col lg={4} className="col-md-12">
              <div
                className={`${classes.featuresContent} ${
                  animation > breakpoint && classes.animations5
                }`}
              >
                <div className={classes.imgContainerFeatures}>
                  <h3>{t("featuresH3Five")}</h3>
                  <img
                    src={icon5}
                    alt=""
                    style={{ marginLeft: "20px", marginBottom: "8px" }}
                  />
                </div>
                <p>{t("featuresPFive")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Element>
  );
};

export default Features;
